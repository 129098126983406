import React from 'react';
import { isEqual } from 'lodash';

import { ISearchDocument, SearchDocumentType } from '@wix/client-search-sdk';
import {
  InjectedBiLoggerProps,
  InjectedEnvironmentProps,
  InjectedErrorMonitorProps,
  InjectedExperimentsProps,
  withBi,
  withEnvironment,
  withErrorMonitor,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { wixSearchBarClickX } from '@wix/bi-logger-wix-search-widget/v2';
import { withSettings, WithSettingsProps } from '@wix/tpa-settings/react';

import { SampleLayout } from '../SampleLayout';
import { SearchResults } from '../SearchResults';

import { IWidgetProps, IWidgetState } from './Widget.types';
import {
  DocumentTypeChangeSource,
  ITab,
  SearchRequestStatus,
} from '../../types/types';

import { DocumentClickOrigin } from '../../platform/searchResultsControllerStore';
import {
  buildSearchResultsUrl,
  toLocationSearchRequest,
} from '../../../../../lib/location';
import { getTotalPages } from '../../platform/pagination';
import {
  getTabAccessibilityLabelTranslations,
  getTabLabelTranslations,
  getTabLabelWithCountTranslations,
} from '../../../../../lib/tabs';
import { LayoutProps, SampleGroup } from '../Layout.types';
import { LayoutItem } from '../LayoutItem.types';
import {
  isProductCollectionsFacetVisible,
  ProductFacets,
} from '../../../../../integrations/stores/ProductFacets';
import { ForumFacets } from '../../../../../integrations/forum/ForumFacets';
import settingsParams from '../../../settingsParams';
import { FacetsEmptyMessage } from '../FacetsEmptyMessage';
import {
  InjectedAppSettingsProps,
  InjectedSearchEnvironmentProps,
  withAppSettings,
  withSearchEnvironment,
} from '../../hocs';
import { ScrollToWidget } from '../../../../../lib/scrollToWidget';
import { InjectedHostProps, withHost } from '../../hocs/withHost';
import {
  findAppViewerOverrides,
  LayoutComponentMap,
} from '../../../../../integrations/appsViewerOverrides';
import { ListLayout } from '../ListLayout';
import { findAppDescriptor } from '../../../../../integrations/apps';
import { AppViewerOverrides } from '../../../../../integrations/types';
import { SlotsPlaceholder } from '@wix/widget-plugins-ooi';
import { SLOTS } from '../../../../../lib/slots';

export type WidgetComponentProps = WithTranslation &
  WithSettingsProps &
  IWidgetProps &
  InjectedEnvironmentProps &
  InjectedBiLoggerProps &
  InjectedExperimentsProps &
  InjectedErrorMonitorProps &
  InjectedAppSettingsProps &
  InjectedSearchEnvironmentProps &
  InjectedHostProps;

export class WidgetComponent extends React.Component<
  WidgetComponentProps,
  IWidgetState
> {
  state = {
    searchQuery: this.props.searchRequest.query,
  };

  listRef: React.RefObject<HTMLUListElement & HTMLDivElement> =
    React.createRef();
  searchResultsRef: React.RefObject<HTMLDivElement> = React.createRef();

  getTabLabelWithCount = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const tabSettings = this.props.appSettings.categoryList[documentType];
    const titleOverride = tabSettings.useOverride && tabSettings.override;

    if (titleOverride) {
      return `${titleOverride} (${count})`;
    }

    const { experiments, t } = this.props;
    const translations = getTabLabelWithCountTranslations(experiments);
    const titleI18NKey = translations[documentType];
    return titleI18NKey
      ? t(titleI18NKey, {
          number: count,
        })
      : documentType;
  };

  getTabLabel = (documentType: SearchDocumentType): string => {
    const tabSettings = this.props.appSettings.categoryList[documentType];
    const titleOverride = tabSettings.useOverride && tabSettings.override;
    if (titleOverride) {
      return titleOverride;
    }

    const { experiments, t } = this.props;
    const translations = getTabLabelTranslations(experiments);
    const titleI18NKey = translations[documentType];
    return titleI18NKey ? t(titleI18NKey) : documentType;
  };

  getTabAccessibilityLabel = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const { experiments, t } = this.props;
    let title = documentType;

    const translations = getTabAccessibilityLabelTranslations(experiments);
    const titleI18NKey = translations[documentType];

    const tabLabel = this.getTabLabel(documentType);

    if (titleI18NKey) {
      title = t(titleI18NKey, {
        number: count,
        category: tabLabel,
      });
    }

    return title;
  };

  createTab = (documentType: SearchDocumentType): ITab => {
    const { searchResponseTotals } = this.props;
    const count = searchResponseTotals?.[documentType];

    return {
      documentType,
      title: count
        ? this.getTabLabelWithCount(documentType, count)
        : this.getTabLabel(documentType),
      count: count || 0,
    };
  };

  componentDidMount() {
    if (this.props.scrollToWidget) {
      this.scrollToWidget(
        this.props.scrollToWidget === ScrollToWidget.CheckViewportAndScroll,
        true,
      );
    }
  }

  componentDidUpdate(prevProps: WidgetComponentProps) {
    const { scrollToWidget } = this.props;

    const pageChanged =
      prevProps.searchRequest.paging.page !==
      this.props.searchRequest.paging.page;

    const tabChanged =
      prevProps.searchRequest.documentType !==
      this.props.searchRequest.documentType;

    const sortOrderChanged = !isEqual(
      prevProps.searchRequest.ordering,
      this.props.searchRequest.ordering,
    );

    const facetsChanged = !isEqual(
      prevProps.facetFilters,
      this.props.facetFilters,
    );

    // TODO: review viewMode when resolved https://github.com/wix-private/native-components-infra/pull/28
    if (
      prevProps.searchRequest.query !== this.props.searchRequest.query ||
      prevProps.searchEnvironment.viewMode !==
        this.props.searchEnvironment.viewMode ||
      pageChanged ||
      tabChanged ||
      sortOrderChanged ||
      facetsChanged
    ) {
      this.setState({
        searchQuery: this.props.searchRequest.query,
      });
    }

    if (prevProps.scrollToWidget !== scrollToWidget && scrollToWidget) {
      this.scrollToWidget(
        scrollToWidget === ScrollToWidget.CheckViewportAndScroll,
      );
    }

    if (
      this.listRef.current &&
      !isEqual(prevProps.searchRequest, this.props.searchRequest)
    ) {
      // safari (as of 2019-11-18) ignores `preventScroll`:
      // https://bugs.webkit.org/show_bug.cgi?id=178583
      const safariCantPreventScrollX = window.scrollX;
      const safariCantPreventScrollY = window.scrollY;

      if (
        safariCantPreventScrollX !== window.scrollX ||
        safariCantPreventScrollY !== window.scrollY
      ) {
        window.scrollTo(safariCantPreventScrollX, safariCantPreventScrollY);
      }
    }
  }

  isWidgetInViewport(isMount?: boolean) {
    if (this.searchResultsRef.current) {
      let distanceToViewportTop =
        this.searchResultsRef.current?.getBoundingClientRect().top;

      // After navigation to Search Results scroll to top happens
      // But it does not always happen before widget scrollTo
      // This changes calculation as if page is scrolled to top
      if (isMount) {
        distanceToViewportTop += window.scrollY;
      }

      return (
        distanceToViewportTop > 0 && distanceToViewportTop < window.innerHeight
      );
    }

    return false;
  }

  scrollToWidget(checkViewport: boolean, isMount?: boolean) {
    const shouldScroll =
      !this.props.environment.isEditorX &&
      !this.props.environment.isCssPerBreakpoint &&
      (!checkViewport || !this.isWidgetInViewport(isMount));

    if (shouldScroll) {
      this.props.host.scrollTo?.();
    }

    this.props.onScrollToWidget();
  }

  handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleQueryClear = () => {
    const { bi, searchEnvironment } = this.props;
    const { isDemoContent } = searchEnvironment;

    bi.report(wixSearchBarClickX({ isDemo: isDemoContent }));
    this.setState({ searchQuery: '' });
  };

  handlePageChange = ({
    event,
    page,
  }: {
    event: React.MouseEvent<HTMLElement>;
    page: number;
  }) => {
    event.stopPropagation();

    if (shouldKeepDefaultBehavior(event)) {
      return;
    }

    event.preventDefault();

    const { onPageChange } = this.props;

    onPageChange(page);
  };

  handleTabChange = (tabs: (ITab & { title: string })[], tabIndex: number) => {
    const { onDocumentTypeChange } = this.props;
    onDocumentTypeChange(
      tabs[tabIndex].documentType,
      DocumentTypeChangeSource.Tab,
    );
  };

  handleSamplesViewAllClick = (
    e: React.MouseEvent<HTMLElement>,
    documentType: SearchDocumentType,
  ) => {
    e.stopPropagation();

    if (shouldKeepDefaultBehavior(e)) {
      return;
    }

    e.preventDefault();

    const { onDocumentTypeChange } = this.props;

    onDocumentTypeChange(documentType, DocumentTypeChangeSource.ViewAllButton);
  };

  handleSubmit = () => {
    const { onQuerySubmit } = this.props;
    const { searchQuery } = this.state;

    onQuerySubmit(searchQuery);
  };

  handleSearchResultClick = (
    e: React.MouseEvent<HTMLElement>,
    item: ISearchDocument,
    index: number,
    clickOrigin: DocumentClickOrigin,
  ) => {
    if (shouldKeepDefaultBehavior(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    this.props.onDocumentClick(item, index, clickOrigin);
  };

  buildPageUrl = (page: number): string => {
    const locationRequest = {
      ...toLocationSearchRequest(
        this.props.searchRequest,
        this.props.facetFilters,
      ),
      page,
    };

    return buildSearchResultsUrl(
      this.props.searchResultsAbsoluteUrl,
      locationRequest,
      this.props.locationQuery,
    );
  };

  getDemoDocuments = (documents: LayoutItem[]) => {
    const { t } = this.props;

    return documents.map((document) => {
      return Object.entries(document).reduce<LayoutItem>(
        (acc, [key, value]) => {
          (acc as Record<string, any>)[key] =
            typeof value === 'string' ? t(value) : value;
          return acc;
        },
        {} as LayoutItem,
      );
    });
  };

  buildDocuments = (documents: LayoutItem[]) => {
    const { isDemoContent } = this.props.searchEnvironment;

    return isDemoContent ? this.getDemoDocuments(documents) : documents;
  };

  buildSamples = (items: SampleGroup[]) => {
    const { isDemoContent } = this.props.searchEnvironment;

    return isDemoContent
      ? items.map((item) => ({
          ...item,
          documents: this.buildDocuments(item.documents),
        }))
      : items;
  };

  getVisibleTabsWithTitles = (): ITab[] => {
    const { visibleDocumentTypes } = this.props;
    return visibleDocumentTypes.map(this.createTab);
  };

  getActiveTabIndex = (visibleTabsWithTitles: ITab[]) => {
    const { searchRequest } = this.props;
    const index: number =
      visibleTabsWithTitles.findIndex(
        (tab) => tab.documentType === searchRequest.documentType,
      ) || 0;
    return index >= 0 ? index : 0;
  };

  getResultCount = (visibleTabsWithTitles: ITab[]): number => {
    const { searchResponse, searchRequest } = this.props;

    return this.isAllTab(searchRequest.documentType)
      ? (visibleTabsWithTitles.length > 0 &&
          visibleTabsWithTitles[this.getActiveTabIndex(visibleTabsWithTitles)]
            .count) ||
          0
      : searchResponse.totalResults;
  };

  getTotalCount = (visibleTabsWithTitles: ITab[]) => {
    return visibleTabsWithTitles.length > 0
      ? visibleTabsWithTitles[this.getActiveTabIndex(visibleTabsWithTitles)]
          .count
      : 0;
  };

  getSamplesWithTitles = () => {
    const { searchSamples, visibleDocumentTypes, searchResponseTotals } =
      this.props;
    return searchSamples
      .filter((sample) => visibleDocumentTypes.includes(sample.documentType))
      .map((s) => ({
        ...s,
        accessibilityLabel: searchResponseTotals
          ? this.getTabAccessibilityLabel(
              s.documentType,
              searchResponseTotals[s.documentType] || 0,
            )
          : this.getTabLabel(s.documentType),
        title: searchResponseTotals
          ? this.getTabLabelWithCount(
              s.documentType,
              searchResponseTotals[s.documentType] || 0,
            )
          : this.getTabLabel(s.documentType),
      }));
  };

  getRenderProps = (
    documentType: SearchDocumentType,
  ): Pick<LayoutProps<LayoutItem>, 'renderItem' | 'renderItemExtention'> => {
    const appViewerOverrides = findAppViewerOverrides(documentType);
    const Item = appViewerOverrides?.Item;
    const ItemDecoration = appViewerOverrides?.ItemDecoration;

    const extraProps = appViewerOverrides?.extraProps?.reduce(
      (props, propKey) => {
        return {
          ...props,
          [propKey]: this.props[propKey],
        };
      },
      {},
    );

    return {
      ...(Item && {
        renderItem: (props, key) => (
          <Item {...props} {...extraProps} key={key} />
        ),
      }),
      ...(ItemDecoration && {
        renderItemExtention: (item) => (
          <ItemDecoration item={item} {...extraProps} />
        ),
      }),
    };
  };

  isAllTab = (documentType: SearchDocumentType | undefined) => {
    return documentType === SearchDocumentType.All;
  };

  render() {
    const {
      appSettings,
      searchEnvironment,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      settings,
      experiments,
      t,
      apiErrorDetails,
      selectedSortOption,
      visibleDocumentTypes,
      onFacetsFilterChange,
      onFacetsFilterReset,
      onSortChange,
      facets,
      facetFilters,
    } = this.props;

    const { isDemoContent } = searchEnvironment;

    const { categoryList, isProductPriceVisible } = appSettings;
    const { searchQuery } = this.state;

    // TODO: remove when the widget starts to use SSR-rendered screen instead of loading indicator
    if (!categoryList) {
      return <div />;
    }

    const totalPages = getTotalPages(
      searchRequest.paging.pageSize,
      searchResponse.totalResults,
    );

    const visibleTabsWithTitles = this.getVisibleTabsWithTitles();
    const activeTabIndex = this.getActiveTabIndex(visibleTabsWithTitles);
    const selectedDocumentType =
      visibleTabsWithTitles[activeTabIndex]?.documentType ??
      SearchDocumentType.All;

    const totalCount = visibleTabsWithTitles
      .filter(({ documentType }) => !this.isAllTab(documentType))
      .reduce((total, tab) => {
        return total + tab.count;
      }, 0);

    const allTab = visibleTabsWithTitles.find(({ documentType }) =>
      this.isAllTab(documentType),
    );

    if (allTab) {
      allTab.title =
        totalCount > 0
          ? this.getTabLabelWithCount(SearchDocumentType.All, totalCount)
          : this.getTabLabel(SearchDocumentType.All);
    }

    const shouldRenderSamples = this.isAllTab(searchRequest.documentType);

    const samplesWithTitles = shouldRenderSamples
      ? this.getSamplesWithTitles()
      : [];

    const documentType = searchRequest.documentType || SearchDocumentType.All;
    const items = this.buildDocuments(searchResponse.documents);
    const tabAccessibilityLabelTranslations =
      getTabAccessibilityLabelTranslations(experiments);
    const tabLabelTranslations = getTabLabelTranslations(experiments);

    const shouldRenderProductsSlot =
      this.props.searchEnvironment.isProductSlotInstalled &&
      documentType === SearchDocumentType.Products;

    const listProps: LayoutProps<LayoutItem> = {
      items,
      listRef: this.listRef,
      onItemLinkClick: this.handleSearchResultClick,
      label: searchResponseTotals
        ? t(tabAccessibilityLabelTranslations[documentType], {
            number: searchResponseTotals[documentType],
            category: this.getTabLabel(documentType),
          })
        : t(tabLabelTranslations[documentType]),
    };

    const failed = searchRequestStatus === SearchRequestStatus.Failed;

    const hasAnyProducts =
      !!searchResponseTotals?.[SearchDocumentType.Products];

    const hasAnyProductFacets =
      isProductCollectionsFacetVisible({ facets, filter: facetFilters }) ||
      isProductPriceVisible;

    const shouldShowFacets =
      !this.props.environment.isEditorX &&
      !this.props.environment.isCssPerBreakpoint &&
      !failed;

    const shouldShowProductFacets =
      !shouldRenderProductsSlot &&
      documentType === SearchDocumentType.Products &&
      shouldShowFacets &&
      hasAnyProducts &&
      settings.get(settingsParams.isProductsFacetsEnabled) &&
      hasAnyProductFacets;

    const shouldShowForumFacets =
      documentType === SearchDocumentType.Forums &&
      shouldShowFacets &&
      settings.get(settingsParams.isForumsFacetsEnabled);

    const showAllTabsHiddenNotification = !visibleDocumentTypes.filter(
      (type) => !this.isAllTab(type),
    ).length;

    const demoContentNotificationText = showAllTabsHiddenNotification
      ? t('searchResults.allTabsHiddenNotification')
      : t('searchResults.demoContentNotificationText');

    const extendLayoutItem = (
      item: LayoutItem,
      components: AppViewerOverrides | undefined,
    ) => {
      // This is redundant and only left for SamplesLayout
      const ItemDecoration = components?.ItemDecoration;
      if (!ItemDecoration) {
        return null;
      }
      const extraProps = components?.extraProps?.reduce((props, key) => {
        return {
          ...props,
          [key]: this.props[key],
        };
      }, {});

      return <ItemDecoration item={item} {...extraProps} />;
    };

    const renderLayout = () => {
      if (this.isAllTab(documentType)) {
        return null;
      }
      if (shouldRenderProductsSlot) {
        return <SlotsPlaceholder slotId={SLOTS.products.id} />;
      }
      const layout = findAppDescriptor(documentType)?.layout;
      if (!layout) {
        return (
          <ListLayout {...listProps} {...this.getRenderProps(documentType)} />
        );
      }
      const ItemsLayout = LayoutComponentMap[layout];

      return (
        <ItemsLayout {...listProps} {...this.getRenderProps(documentType)} />
      );
    };

    return (
      <SearchResults
        searchResultsRef={this.searchResultsRef}
        searchQuery={
          isDemoContent ? t('resultsFoundMessage.demoQuery') : searchQuery
        }
        searchPlaceholder={settings.get(settingsParams.searchBarPlaceholder)}
        searchClearLabel={t('searchResults.clearLabel')}
        isSearchBarEnabled={settings.get(settingsParams.isSearchBarEnabled)}
        activeTabIndex={activeTabIndex}
        selectedDocumentType={selectedDocumentType}
        onTabChange={(tabIndex: number) =>
          this.handleTabChange(visibleTabsWithTitles, tabIndex)
        }
        onQueryChange={this.handleQueryChange}
        onQueryClear={this.handleQueryClear}
        onSubmit={this.handleSubmit}
        onFacetsFilterReset={onFacetsFilterReset}
        isPaginationHidden={shouldRenderSamples || shouldRenderProductsSlot}
        isStatusLineHidden={shouldRenderProductsSlot}
        currentPage={searchRequest.paging.page}
        totalPages={totalPages > 1 ? totalPages : null}
        onPageChange={this.handlePageChange}
        isLoading={searchRequestStatus === SearchRequestStatus.Loading}
        resultsCount={this.getResultCount(visibleTabsWithTitles)}
        totalCount={this.getTotalCount(visibleTabsWithTitles)}
        demoContentNotificationText={demoContentNotificationText}
        buildPageUrl={this.buildPageUrl}
        selectedSortOption={selectedSortOption}
        onSortChange={onSortChange}
        visibleTabsWithTitles={visibleTabsWithTitles}
        lastQuery={this.props.searchRequest.query}
        failed={failed}
        apiErrorDetails={apiErrorDetails}
        facets={
          shouldShowProductFacets ? (
            <ProductFacets
              facets={facets}
              facetFilters={facetFilters}
              onFacetsFilterChange={onFacetsFilterChange}
            />
          ) : shouldShowForumFacets ? (
            <ForumFacets
              facets={facets}
              facetFilters={facetFilters}
              onFacetsFilterChange={onFacetsFilterChange}
              onFacetsFilterReset={onFacetsFilterReset}
            />
          ) : undefined
        }
      >
        {this.isAllTab(documentType) &&
          !!this.getTotalCount(visibleTabsWithTitles) && (
            <SampleLayout
              {...listProps}
              results={this.buildSamples(samplesWithTitles)}
              onViewAllClick={this.handleSamplesViewAllClick}
              renderItemExtention={(item) =>
                extendLayoutItem(
                  item,
                  findAppViewerOverrides(item.documentType),
                )
              }
            />
          )}

        {(shouldShowProductFacets || shouldShowForumFacets) &&
        items.length === 0 ? (
          <FacetsEmptyMessage onFacetsFilterReset={onFacetsFilterReset} />
        ) : (
          renderLayout()
        )}
      </SearchResults>
    );
  }
}

export const Widget = withHost(
  withSearchEnvironment(
    withAppSettings(
      withErrorMonitor(
        withExperiments(
          withSettings(
            withBi(withEnvironment(withTranslation()(WidgetComponent))),
          ),
        ),
      ),
    ),
  ),
);

function isAnchorElement(element: HTMLElement): element is HTMLAnchorElement {
  return element.nodeName === 'A';
}

// handle opening links in a new tab
// https://github.com/zeit/next.js/blob/42771f6451df6ba2dbda51e60968e9edcfde74af/packages/next/client/link.tsx#L149-L159
function shouldKeepDefaultBehavior(e: React.MouseEvent<HTMLElement>): boolean {
  const currentTarget = e.currentTarget;
  return (
    isAnchorElement(currentTarget) &&
    ((currentTarget.target && currentTarget.target !== '_self') ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey ||
      (e.nativeEvent && e.nativeEvent.button === 1))
  );
}
